$("#contact_form").submit(function (event) {

    $form = $(this);
    event.preventDefault();

    $('.js-errors').html('').hide();
    $('.js-success').html('').hide();

    var formData = $form.serialize();

    $.post($form.data('action'), formData, function( response ) {
        if(response.success==0){
            showContactFeedback(response.message, 'success');
        }else{
            $('#contact_form')[0].reset();
            console.log(response.message);
            var target = $('.js-success');
            $(target).html('')
            $(target).append('<div>'+response.message+'</div>')
            $(target).fadeIn()
            showContactFeedback(response.message, 'warning');
        }
    }).fail(function(error) {
        var target = $('.js-errors');
        $(target).html('')
        $(target).fadeIn()
        $(target).append('<div>'+texts['contact']['feedback_ko']+'</div>')
        setTimeout(function() {
            $(target).fadeOut()
        }, 4000)
    });
});


function showContactFeedback(message, alert_type, expires = false, errors = {}) {
    var feedback = document.querySelector('.js-feedback');
    // Reset feedback content
    feedback.innerHTML = '';

    var container = document.createElement('div');
    container.className = 'alert ' + 'alert-' + alert_type;

    // If errors
    if (Object.keys(errors).length > 0) {
        var errorUl = document.createElement('ul');
        errorUl.style.margin = 0;
        errorUl.style.paddingLeft = "20px";

        Object.values(errors).forEach(function (error, i) {
            error.forEach(function (err) {
                var errorLi = document.createElement('li');
                errorLi.innerText = err;
                errorUl.appendChild(errorLi);
            })
        });

        container.appendChild(errorUl);
    } else {
        var text = document.createElement('div');
        text.innerHTML = message;
        container.appendChild(text);
    }

    // Show feedback
    feedback.appendChild(container);

    if (expires) {
        setTimeout(function() {
            feedback.innerHTML = '';
        }, 6000);
    }
}
